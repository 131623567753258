"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNegativelySigned = void 0;
/**
 * Returns `true` if the passed value less than `0`, or equal to `-0`.
 */
var isNegativelySigned = function (n) {
  return n < 0 || Object.is(n, -0);
};
exports.isNegativelySigned = isNegativelySigned;
