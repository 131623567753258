"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseISODuration = void 0;
var units_1 = require("./units");
var negate_1 = require("../negate");
var numberUtils_1 = require("./numberUtils");
var millisecondsPattern = '(?:[,.](\\d{1,3})\\d*)?';
var unitPattern = function (unit) {
  return "(?:(-?\\d+)".concat(unit, ")?");
};
var createDurationParser = function (regex, unitsOrder) {
  return function (duration) {
    var match = duration.match(regex);
    if (!match) {
      return null;
    }
    var isDurationNegative = match[1] === '-';
    var unitStrings = match.slice(2);
    // Must have at least one unit match
    if (unitStrings.every(function (value) {
      return value === undefined;
    })) {
      return null;
    }
    var unitNumbers = unitStrings.map(function (value, i) {
      value = value !== null && value !== void 0 ? value : '0';
      var isMilliseconds = i === unitStrings.length - 1;
      return isMilliseconds
      // Pad the end of the millisecond values. For example, when taking the "6"
      // portion from the string "PT3.6S", we need to interpret that as "600
      // milliseconds".
      ? Number(value.padEnd(3, '0')) : Number(value);
    });
    var output = __assign({}, units_1.ZERO);
    unitsOrder.forEach(function (unit, i) {
      output[unit] = unitNumbers[i];
    });
    // Milliseconds don't have their own minus symbol. It depends on the symbol
    // before the seconds value.
    if ((0, numberUtils_1.isNegativelySigned)(output.seconds)) {
      output.milliseconds *= -1;
    }
    return isDurationNegative ? (0, negate_1.negate)(output) : output;
  };
};
/**
 * Parse a duration string expressed in one of the following formats:
 *
 * - PYYYYMMDDThhmmss
 * - PYYYY-MM-DDThh:mm:ss
 */
var parseFullFormatISODuration = createDurationParser(new RegExp(['^(-)?P', '(\\d{4})', '-?', '(\\d{2})', '-?', '(\\d{2})', 'T', '(\\d{2})', ':?', '(\\d{2})', ':?', '(\\d{2})', millisecondsPattern, '$'].join('')), ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']);
/**
 * Parse a duration string expressed via number and unit character pairs. For
 * example:
 *
 * - P6D
 * - P1Y2D
 * - P2DT6H2,5S
 */
var parseUnitsISODuration = createDurationParser(new RegExp(['^(-)?P', unitPattern('Y'), unitPattern('M'), unitPattern('W'), unitPattern('D'), '(?:T', unitPattern('H'), unitPattern('M'), unitPattern("".concat(millisecondsPattern, "S")), ')?$'].join('')), ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']);
/**
 * Parse an ISO 8601 duration string into an object.
 *
 * The units of duration are not normalized. For example, the string `"P365D"`
 * doesn't get converted to `{ years: 1 }` since not all years are the same
 * length.
 *
 * @example parseISODuration('P365D') // { days: 365 }
 */
var parseISODuration = function (duration) {
  var output = parseUnitsISODuration(duration) || parseFullFormatISODuration(duration);
  if (output === null) {
    throw new SyntaxError("Failed to parse duration. \"".concat(duration, "\" is not a valid ISO duration string."));
  }
  return output;
};
exports.parseISODuration = parseISODuration;
