"use strict";

var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.subtract = void 0;
var negate_1 = require("./negate");
var sum_1 = require("./sum");
/**
 * Subtract durations from the first duration argument.
 *
 * @example
 * subtract({ days: 1 }, { hours: 12 })
 * // { days: 1, hours: -12 }
 */
var subtract = function (duration) {
  var durationsToSubtract = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    durationsToSubtract[_i - 1] = arguments[_i];
  }
  return sum_1.sum.apply(void 0, __spreadArray([duration], durationsToSubtract.map(negate_1.negate), false));
};
exports.subtract = subtract;
