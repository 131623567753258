"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cleanDurationObject = void 0;
var units_1 = require("./units");
var cleanDurationObject = function (duration) {
  var output = __assign({}, duration);
  units_1.UNITS.forEach(function (key) {
    // Convert `-0` to `0`. Both values will evaluate as `true` here.
    if (output[key] === 0) {
      output[key] = 0;
    }
  });
  return output;
};
exports.cleanDurationObject = cleanDurationObject;
