"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = void 0;
var units_1 = require("./lib/units");
var parseISODuration_1 = require("./lib/parseISODuration");
var validate_1 = require("./lib/validate");
var cleanDurationObject_1 = require("./lib/cleanDurationObject");
var baseParse = function (duration) {
  if (typeof duration === 'string') {
    return (0, parseISODuration_1.parseISODuration)(duration);
  }
  if (typeof duration === 'number') {
    return __assign(__assign({}, units_1.ZERO), {
      milliseconds: duration
    });
  }
  return __assign(__assign({}, units_1.ZERO), duration);
};
/**
 * Parse various duration formats to a simple suration object.
 */
var parse = function (duration) {
  var output = baseParse(duration);
  (0, validate_1.validate)(output);
  return (0, cleanDurationObject_1.cleanDurationObject)(output);
};
exports.parse = parse;
