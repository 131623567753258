"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkAllUnitsNegative = void 0;
var negate_1 = require("../negate");
var parse_1 = require("../parse");
var units_1 = require("./units");
/**
 * Get an indication of whether all of the non-zero units in a duration are
 * negative. If they are, return a positive representation of the duration,
 * with `isAllNegative` set to `true`.
 */
var checkAllUnitsNegative = function (duration) {
  var parsed = (0, parse_1.parse)(duration);
  var hasPositive = false;
  var hasNegative = false;
  units_1.UNITS.forEach(function (unit) {
    var value = parsed[unit];
    if (value < 0) {
      hasNegative = true;
    } else if (value > 0) {
      hasPositive = true;
    }
  });
  if (hasNegative && !hasPositive) {
    return {
      isAllNegative: true,
      maybeAbsDuration: (0, negate_1.negate)(parsed)
    };
  }
  return {
    isAllNegative: false,
    maybeAbsDuration: parsed
  };
};
exports.checkAllUnitsNegative = checkAllUnitsNegative;
