"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toYears = exports.toMonths = exports.toWeeks = exports.toDays = exports.toHours = exports.toMinutes = exports.toSeconds = exports.toUnit = exports.toMilliseconds = void 0;
var parse_1 = require("./parse");
var units_1 = require("./lib/units");
/**
 * Convert the input value to milliseconds represented by a duration object.
 * If a number is passed this is returned verbatim as the number
 * of milliseconds.
 *
 * @example toMilliseconds({ days: 1 }) // 86400000
 */
var toMilliseconds = function (duration) {
  var parsed = (0, parse_1.parse)(duration);
  return units_1.UNITS_META.reduce(function (total, _a) {
    var unit = _a.unit,
      milliseconds = _a.milliseconds;
    return total + parsed[unit] * milliseconds;
  }, 0);
};
exports.toMilliseconds = toMilliseconds;
/**
 * Convert the input value to the specificed unit.
 * @example toUnit({ minutes: 2 }, 'seconds') // 120
 */
var toUnit = function (duration, unit) {
  return (0, exports.toMilliseconds)(duration) / units_1.UNITS_META_MAP[unit].milliseconds;
};
exports.toUnit = toUnit;
var createDurationConverter = function (unit) {
  return function (duration) {
    return (0, exports.toUnit)(duration, unit);
  };
};
/**
 * Convert the input value to seconds.
 * @example toSeconds({ minutes: 2 }) // 120
 */
exports.toSeconds = createDurationConverter('seconds');
/**
 * Convert the input value to minutes.
 * @example toMinutes({ hours: 1, minutes: 10 }) // 70
 */
exports.toMinutes = createDurationConverter('minutes');
/**
 * Convert the input value to hours.
 * @example toHours({ days: 1 }) // 24
 */
exports.toHours = createDurationConverter('hours');
/**
 * Convert the input value to days.
 * @example toDays({ hours: 12 }) // 0.5
 */
exports.toDays = createDurationConverter('days');
/**
 * Convert the input value to weeks.
 * @example toWeeks({ days: 14 }) // 2
 */
exports.toWeeks = createDurationConverter('weeks');
/**
 * Convert the input value to months.
 * Note, this is a rough approximation as the length of a month is variable.
 *
 * @example toMonths({ months: 10, days: 365 }) // 11
 */
exports.toMonths = createDurationConverter('months');
/**
 * Convert the input value to years.
 * Note, this is a rough approximation as the length of a year is variable.
 *
 * @example toYears({ years: 10, days: 365 }) // 11
 */
exports.toYears = createDurationConverter('years');
