"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toString = void 0;
var isZero_1 = require("./isZero");
var getUnitCount_1 = require("./lib/getUnitCount");
var units_1 = require("./lib/units");
var checkAllUnitsNegative_1 = require("./lib/checkAllUnitsNegative");
var joinComponents = function (component, delimiter) {
  return component.join('').replace(/\./g, delimiter);
};
/**
 * Stringify a duration into an ISO duration string.
 *
 * @example
 * toString({ years: 1, hours: 6 }) // 'P1YT6H'
 * toString(6000) // 'PT6S'
 */
var toString = function (duration, options) {
  if (options === void 0) {
    options = {};
  }
  var finalOptions = __assign({
    // Commas are mentioned in the spec as the preferred decimal delimiter
    decimalDelimiter: ','
  }, options);
  // Zero values are a special case, since "P" is not a valid value.
  // At least one unit must be specified.
  if ((0, isZero_1.isZero)(duration)) {
    return 'P0D';
  }
  var _a = (0, checkAllUnitsNegative_1.checkAllUnitsNegative)(duration),
    parsed = _a.maybeAbsDuration,
    isAllNegative = _a.isAllNegative;
  // Weeks should not be included in the output, unless it is the only unit.
  if ((0, getUnitCount_1.getUnitCount)(parsed) === 1 && parsed.weeks !== 0) {
    return "P".concat(parsed.weeks, "W");
  }
  var components = {
    period: [],
    time: []
  };
  // Some units should be converted before stringifying.
  // For example, weeks should not be mixed with other units, and milliseconds
  // don't exist on ISO duration strings.
  units_1.UNITS_META.forEach(function (_a) {
    var fromUnit = _a.unit,
      toUnit = _a.stringifyConvertTo;
    if (toUnit == null) {
      return;
    }
    var millisecondValue = parsed[fromUnit] * units_1.UNITS_META_MAP[fromUnit].milliseconds;
    parsed[toUnit] += millisecondValue / units_1.UNITS_META_MAP[toUnit].milliseconds;
    parsed[fromUnit] = 0;
  });
  // Push each non-zero unit to its relevant array
  units_1.UNITS_META.forEach(function (_a) {
    var unit = _a.unit,
      ISOPrecision = _a.ISOPrecision,
      ISOCharacter = _a.ISOCharacter;
    var value = parsed[unit];
    if (ISOPrecision != null && value !== 0) {
      components[ISOPrecision].push("".concat(value).concat(ISOCharacter));
    }
  });
  // Build output string
  var output = "P".concat(joinComponents(components.period, finalOptions.decimalDelimiter));
  if (components.time.length) {
    output += "T".concat(joinComponents(components.time, finalOptions.decimalDelimiter));
  }
  // Avoid "P-1DT-1H". Instead, output "-P1DT1H".
  // https://github.com/dlevs/duration-fns/issues/22
  if (isAllNegative) {
    output = "-".concat(output);
  }
  return output;
};
exports.toString = toString;
