"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.between = void 0;
var units_1 = require("./lib/units");
/**
 * Gets the difference between two dates, expressed as a duration object.
 *
 * @example
 * between('2022-01-01', '2020-01-01') // { years: -2, months: 0, weeks: 0, ... }
 */
var between = function (date1, date2) {
  var a = new Date(date1);
  var b = new Date(date2);
  var output = __assign({}, units_1.ZERO);
  units_1.UNITS_META.forEach(function (_a) {
    var unit = _a.unit,
      dateGetter = _a.dateGetter;
    output[unit] = dateGetter(b) - dateGetter(a);
  });
  return output;
};
exports.between = between;
