"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.negate = void 0;
var units_1 = require("./lib/units");
var parse_1 = require("./parse");
/**
 * Gets the negative of the input duration.
 *
 * @example
 * negate({ days: -1 }) // { days: 1 }
 * negate({ days: -1, hours 2 }) // { days: 1, hours: -2 }
 */
var negate = function (duration) {
  var output = __assign({}, (0, parse_1.parse)(duration));
  units_1.UNITS.forEach(function (unit) {
    output[unit] = output[unit] === 0 ? 0 : -output[unit];
  });
  return output;
};
exports.negate = negate;
